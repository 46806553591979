import React from 'react';

import { SEO, Layout, Header, InfoSection, GiftFlight, LatestNews, Footer } from '../../components';

export default ({ pageContext: ctx }) => {
  const {
    news,
    heroSection,
    infoSection,
    backgroundSection,
    translationPath,
    metadata,
    i18n_lang: lang,
  } = ctx;
  console.log({ ctx });
  return (
    <Layout>
      <SEO {...metadata.metadata} />
      <Header
        {...heroSection}
        lang={lang}
        className="home-page"
        translationPath={translationPath}
      />
      <InfoSection {...infoSection} lang={lang} />
      <GiftFlight {...backgroundSection} lang={lang} />
      <LatestNews news={news} lang={lang} />
      <Footer lang={lang} />
    </Layout>
  );
};
